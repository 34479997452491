<template>
  <div class="bg">
    <img class="waves" :src="require('@/assets/waves_bg.svg')" alt="waves_bg" />
  </div>
</template>

<script>
export default {
  name: "BackgroundComponent",
};
</script>
